/* input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: white;
} */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400');

@media (min-width: 1200px) {
	#weather-data {
		font-size: 20px;
		color: white;
	}
	
	
	.button {
		margin-top: -10px;
		margin-left: 0;
		margin-right: 0;
		input[type="radio"] {
			opacity: 0.011;
			z-index: 100;
		}
		label {
			cursor: pointer;
			background-color: $secondary_bg;
			color: white;
			z-index: 90;
			line-height: 0.6em;
		}
	}
	
	.weather__value {
		color: #fff;
		font-weight: 200;
	}
	
	.weather__error {
		color: #eb722c;
		font-size: 12px;
		letter-spacing: 1px;
		font-weight: 200;
	}
	p#location {
		font-family: "Open Sans", serif;
		color: #fff;
		font-size: 15px;
		text-align: center;
	}
	.error {
		color: #eb722c;
		font-size: 12px;
		text-align: center;
	}
}


@media (min-width: 992px) and (max-width: 1199px) {
	.button {
		margin-top: -10px;
		margin-left: 0;
		margin-right: 0;
		input[type="radio"] {
			opacity: 0.011;
			z-index: 100;
		}
		label {
			cursor: pointer;
			background-color: $secondary_bg;
			color: white;
			z-index: 90;
			line-height: 0.6em;
		}
	}
	
	.weather__key {
		color: #eb722c;
		border-bottom: solid 2px rgba(255,255,255,0.06);
		padding: 20px 0 20px 0;
		font-weight: 400;
		&:last-child {
			border: 0;
		}
	}
	.weather__value {
		color: #fff;
		font-weight: 200;
		font-size: 12px;
	}
	
	.weather__error {
		color: #eb722c;
		font-size: 12px;
		letter-spacing: 1px;
		font-weight: 200;
	}
	p#location {
		font-family: "Open Sans", serif;
		color: #fff;
		font-size: 12px;
		text-align: center;
	}
	.error {
		color: #eb722c;
		font-size: 8px;
		text-align: center;
	}
}
